import React, { useEffect, useState, ReactElement, useRef } from 'react';
import uniqid from 'uniqid';
import { Link as GatsbyLink } from 'gatsby';
import { Box, Grid as CGrid, GridItem, Link, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import Instructors from '../../../components/Instructors';
import Grid from '../../../components/Grid';
import { Text2, Text3 } from '../../../components/atoms/body';
import FrequentAskedQuestions from '../../../components/FAQ';
import Footer from '../../../components/Footer';
import CampusList from '../../../components/CampusList';
import { LayoutGrid, LayoutGridColor, LayoutCourseCampusList } from './styled';
import { courseLookup, allPass } from '../../../lib/utils';
import { SaveCourseVisited } from '../../../storage/provider';
import { webCategoryToDato } from '../../../../build-utils/dato-utils';
import BlogArticlesSample from '../../../components/BlogArticlesSample';
import useUserTimezone from '../../../lib/useUserTimezone';
import { processDatoCmsAssests } from '../../../services/datoUtilities';
import { CourseProps } from '..';
import NewCampusCourseCta from '../../../components/CtaCampusCourse/experiments';
import HeaderHome from '../../../components/HeaderHome';
import CtaCampusCourse from '../../../components/CtaCampusCourse';
import useScroll from '../../../hooks/useScroll';
import NewNavbar from '../../../components/CampusCourseNavbar/experiments';
import NewCourseInformation from '../../../components/InformationAboutTheCourse/experiments';
import CourseImage from '../../../components/CtaCampusCourse/experiments/CourseImage';
import Cohorts from '../../../components/interfaces/Cohorts';
import useUserLocation from '../../../hooks/useUserLocation';
import Banner from '../../../components/Banner';
import ShortCourseEntryPoint from '../../../containers/ShortCourseEntryPoint';
import Apprenticeship from '../../../containers/Apprenticeship';

const Course = (props: CourseProps): ReactElement => {
  const { data } = props;
  const { courseCode, locale, cohorts, pageName } = props.pageContext;
  const { t } = useTranslation();
  const { userLocation } = useUserLocation();
  const [userTimezone] = useUserTimezone();

  const navbarRef = useRef<HTMLDivElement>(null);
  const scrollConfig = { offset: navbarRef?.current?.offsetHeight };

  const [, headerRef, headerIsVisible] = useScroll(scrollConfig, '-5%');
  const [scrollToCourseInfo, courseInfoRef, courseInfoIsVisible] =
    useScroll(scrollConfig);
  const [scrollToCampus, campusRef, campusIsVisible] = useScroll(scrollConfig);
  const [scrollToTeachers, teachersRef, teachersIsVisible] =
    useScroll(scrollConfig);
  const [scrollToForWho, forWhoRef, forWhoIsVisible] = useScroll(scrollConfig);
  const [scrollToWhy, whyRef, whyIsVisible] = useScroll(scrollConfig);
  const [scrollToFaq, faqRef, faqIsVisible] = useScroll(scrollConfig);

  const assets = processDatoCmsAssests(data.allDatoCmsAssetsByPage);

  SaveCourseVisited(`${courseCode}ft`);

  const courseName = t(`course-list:courseList.${courseCode}.title`);
  const courseI18n = `course-${courseLookup(courseCode)}`;

  const navbarMenu = [
    {
      isVisible: courseInfoIsVisible || headerIsVisible,
      key: t('course-list:courseList.navbar.courseInfo'),
      ref: courseInfoRef,
      scroll: scrollToCourseInfo,
    },
    {
      isVisible: teachersIsVisible,
      key: t('course-list:courseList.navbar.teachers'),
      ref: teachersRef,
      scroll: scrollToTeachers,
    },
    {
      isVisible: campusIsVisible,
      key: t('course-list:common.chooseCampusCTA'),
      ref: campusRef,
      scroll: scrollToCampus,
    },
    {
      isVisible: forWhoIsVisible,
      key: t('course-list:courseList.navbar.forWho'),
      ref: forWhoRef,
      scroll: scrollToForWho,
    },
    {
      isVisible: whyIsVisible,
      key: t('course-list:courseList.navbar.why', { course: courseName }),
      ref: whyRef,
      scroll: scrollToWhy,
    },
    {
      isVisible: faqIsVisible,
      key: t('course-list:courseList.navbar.faq'),
      ref: faqRef,
      scroll: scrollToFaq,
    },
  ];

  const [courseCohorts, setCourseCohorts] = useState<Cohorts[]>(cohorts || []);

  const isFrenchWeb = userLocation?.country === 'FR' && courseCode === 'web';
  const isGermanWebDev = userLocation?.country === 'DE' && courseCode === 'web';

  useEffect(() => {
    if (userTimezone !== null) {
      const cohortFilters = allPass([
        (cohort: Cohorts) => cohort.course_code.startsWith(courseCode),
        (cohort: Cohorts) =>
          cohort.campus_code === 'rmt' && userTimezone
            ? cohort.timezone === userTimezone
            : true,
      ]);

      setCourseCohorts(cohorts.filter(cohortFilters));
    }
  }, []);

  return (
    <>
      <Box>{userLocation?.country === 'NL' && <Banner type="stap" />}</Box>
      <HeaderHome pageName={pageName} ref={headerRef}>
        <NewCampusCourseCta
          image={<CourseImage courseCode={courseCode} />}
          subtitle={t(`${courseI18n}:${courseCode}.mainHeadline.subtitle`)}
          title={
            <Trans
              components={[
                <Text
                  as="span"
                  color="electricBlue.100"
                  key={`${courseName}-title-header`}
                >
                  Web Development
                </Text>,
              ]}
              defaults="Boost your career with a <0>Web Development</0> bootcamp"
              i18nKey={`${courseI18n}:${courseCode}.mainHeadline.title`}
            />
          }
        >
          <CtaCampusCourse
            primaryCtaProps={{
              onClick: scrollToCourseInfo,
              visibility: courseInfoIsVisible
                ? ['hidden', null, 'visible']
                : 'visible',
            }}
            primaryCtaText={t('course-list:common.moreInfoCTA')}
            secondaryCtaProps={{ onClick: scrollToCampus }}
            secondaryCtaText={t('course-list:common.chooseCampusCTA')}
          />
        </NewCampusCourseCta>
      </HeaderHome>
      <NewNavbar
        ctaProps={{ onClick: scrollToCourseInfo }}
        ctaText={t('course-list:common.moreInfoCTA')}
        headerIsVisible={headerIsVisible}
        menu={navbarMenu}
        ref={navbarRef}
        titleForMobile={courseName}
      />
      <Box maxW="1296px" mx="auto">
        {isFrenchWeb && <Apprenticeship mt={3} />}
      </Box>
      <NewCourseInformation
        assets={assets}
        content={t(`${courseI18n}:${courseCode}.courseStructure.descriptions`, {
          returnObjects: true,
          course: courseName,
        })}
        course={courseCode}
        courseName={courseName}
        isCoursePage
        ref={courseInfoRef}
        titleSyllabus={t(`download-syllabus:downloadSyllabus.description`, {
          course: courseName,
        })}
      />
      <ShortCourseEntryPoint courseCode={courseCode} />
      <Instructors
        course={courseName}
        courseCode={courseCode}
        locale={props.pageContext.locale}
        ref={teachersRef}
      />
      <CampusList
        cohorts={courseCohorts}
        courseCode={courseCode}
        extend={LayoutCourseCampusList}
        id="campusList"
        locale={locale}
        ref={campusRef}
        showLinks
      />
      <Box ref={forWhoRef}>
        <Grid
          extend={LayoutGrid}
          title={t(`${courseI18n}:${courseCode}.whoIsItFor.title`, {
            course: courseName,
          })}
        >
          {Object.values(
            t(`${courseI18n}:${courseCode}.whoIsItFor.descriptions`, {
              returnObjects: true,
            })
          ).map(
            (courseInfo: any): JSX.Element => (
              <section key={uniqid()}>
                <Text2 as="h3">{courseInfo.title}</Text2>
                <Text3
                  as="p"
                  dangerouslySetInnerHTML={{ __html: courseInfo.content }}
                />
              </section>
            )
          )}
        </Grid>
      </Box>
      <Box ref={whyRef}>
        <Grid
          extend={LayoutGridColor}
          title={t(`${courseI18n}:${courseCode}.whyShouldYouLearn.title`, {
            course: courseName,
          })}
        >
          {Object.values(
            t(`${courseI18n}:${courseCode}.whyShouldYouLearn.descriptions`, {
              returnObjects: true,
            })
          ).map((courseInfo: any) => (
            <section key={uniqid()}>
              <Text2 as="h3">{courseInfo.title}</Text2>
              {typeof courseInfo.content === 'object' ? (
                Object.values(courseInfo.content).map((content: any) => (
                  <Text3 as="p" key={uniqid()}>
                    {content}
                  </Text3>
                ))
              ) : (
                <Text3
                  as="p"
                  dangerouslySetInnerHTML={{ __html: courseInfo.content }}
                />
              )}
            </section>
          ))}
        </Grid>
      </Box>
      <CGrid
        gap={6}
        maxW="129.6rem"
        mt={6}
        mx={[3, null, 6, 'auto']}
        ref={faqRef}
        templateColumns={['1fr', null, null, 'repeat(12, 1fr)']}
      >
        <GridItem colSpan={[1, null, null, 9]}>
          <Text as="h2" textStyle="title2">
            {t(`${courseI18n}:${courseCode}.faq.title`)}
          </Text>
          <FrequentAskedQuestions category={courseCode} />
          <Text textStyle="body4">
            <Box as="span" color="darkBlues.64">
              {t('course-campus:courseCampus.faq.wantMore')}
            </Box>{' '}
            <Link as={GatsbyLink} to={`/${locale}/faq`} variant="emphasis">
              {t('course-campus:courseCampus.faq.linkText')}
            </Link>
          </Text>
        </GridItem>
      </CGrid>
      <BlogArticlesSample category={webCategoryToDato(courseCode)} />
      <Footer page={pageName} />
    </>
  );
};

export default Course;
