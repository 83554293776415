import React, { useState, useEffect } from 'react';

import { GetUserTimezone, SaveUserTimezone } from '../storage/provider';
import timezones from './timezones';
import { getUserTimezoneQuery } from './graphql';

const acceptedTimezones = [...Object.values(timezones), ''];

const getTimezone = async (): Promise<string | null> => {
  const storagedTimezone = GetUserTimezone();

  if (storagedTimezone && acceptedTimezones.includes(storagedTimezone)) {
    return storagedTimezone;
  }

  try {
    const response = await fetch(
      process.env.IRONHACK_API,
      getUserTimezoneQuery()
    );
    const body = await response.json();
    const timezone = body.data.remoteTimezone;
    return timezone;
  } catch {
    return null;
  }
};

function useUserTimezone() {
  const [timezone, setTimezone] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const setUserTimezone = (timezone) => {
    setTimezone(timezone);
    SaveUserTimezone(timezone);
  };

  useEffect(() => {
    (async (): Promise<void> => {
      if (timezone !== null) {
        return;
      }

      const currentTimezone = await getTimezone();
      setUserTimezone(currentTimezone);
      setIsLoading(false);
    })();
  }, []);

  return [timezone, isLoading, setUserTimezone];
}

export default useUserTimezone;
