import React, { forwardRef } from 'react';

import Section from './styles';
import NavBar from '../NavBar';
import HeaderHomerProps from '../interfaces/HeaderHome';

const HeaderHome = (props, ref): React.ReactElement => {
  const { children, pageName } = props;

  return (
    <Section ref={ref}>
      <NavBar pageName={pageName} />
      {children}
    </Section>
  );
};

export default forwardRef<HTMLDivElement, HeaderHomerProps>(HeaderHome);
