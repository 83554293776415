import styled, { css } from 'styled-components';

import config from '../../../components/config';

const LayoutGrid = css`
  padding-top: 6.4rem;
  padding-bottom: 4.8rem;

  & > h2 {
    margin-bottom: 3.2rem;
  }

  p {
    margin-top: 1.6rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    h2 {
      font-weight: 700;
    }
  }
`;

const LayoutGridColor = css`
  padding-top: 4.8rem;
  padding-bottom: 4.8rem;
  background-color: ${config.palette.greenTransparent('0.1')};

  & > h2 {
    color: ${config.palette.green};
    margin-bottom: 3.2rem;
  }

  p {
    margin-top: 1.6rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    h2 {
      font-weight: 700;
    }
  }
`;

const LayoutCourseCampusList = css`
  padding-top: 4.8rem;
`;

const Article = styled.article`
  width: 55%;
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }
`;

const DownloadSyllabus = styled.article`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 calc((100% - 1296px) / 2);

  > div {
    margin-top: 3.2rem;
    margin-bottom: 4.8rem;
  }

  div {
    width: 50%;
    text-align: center;
  }

  form div {
    width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    div {
      width: 100%;
    }
    > div {
      width: 100%;
      margin-top: 1.6rem;
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }
  }
`;

const ButtonDownload = styled.div`
  padding: 1.4rem 0;
  margin: 1.6rem auto;
  opacity: 0.8;
  border: 0.2rem solid ${config.palette.green};
  border-radius: 0.6rem;
  color: ${config.palette.green};
  font-size: 1.6rem;
  line-height: 125%;
  cursor: pointer;
`;

export {
  LayoutGrid,
  LayoutGridColor,
  LayoutCourseCampusList,
  Article,
  DownloadSyllabus,
  ButtonDownload,
};
