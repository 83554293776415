import styled from 'styled-components';

import config from '../config';

const lightHexagonMobile = '/assets/headers/lightHexagonMobile.svg';
const lightHexagonDesktop = '/assets/headers/lightHexagonDesktop.svg';

export default styled.section`
  background: url(${lightHexagonDesktop}) right top no-repeat;
  padding-bottom: 4rem;

  nav {
    padding-bottom: 6.4rem;
    padding-top: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    background: url(${lightHexagonMobile}) right top no-repeat;
    overflow: hidden;
    padding-bottom: 0;
    position: relative;

    &::before {
      background: 'none';
      background-size: 100% 100%;
      content: ' ';
      height: 137%;
      left: -18%;
      padding-bottom: 6rem;
      position: absolute;
      top: -40%;
      transform: rotate(15deg);
      width: 134%;
      z-index: -1;
    }

    nav {
      padding-top: 1.6rem;
      padding-bottom: 4.8rem;
    }
  }

  .article-menu {
    a,
    div {
      color: ${config.palette.darkBlue};
    }

    nav {
      > a {
        border: 0.1rem solid ${config.palette.darkBlue};
        border-radius: ${config.borderRadius};
      }

      svg path {
        fill: ${config.palette.darkBlue};
      }
    }
  }
`;
