import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import { CoursePage } from '../../interface/template';
import { TDatoCmsGraphQlAssets } from '../../types/datocms';
import NewVariant from './variants/new';

export type CourseData = {
  allDatoCmsAssetsByPage: TDatoCmsGraphQlAssets;
};

export type CourseProps = CoursePage & {
  data: CourseData;
};

const Course = (props: CourseProps): ReactElement => <NewVariant {...props} />;

export const query = graphql`
  query ($locale: String!, $datoLocale: String!, $defaultLanguage: String!) {
    locales: allLocale(
      filter: {
        lng: { in: [$locale, $defaultLanguage] }
        ns: {
          regex: "/(menu)|(application-form)|(eyebrow)|(course-list)|(staff)|(short-courses)|(campus-list)|(course-campus)|(course-data-analytics)|(course-ux-ui-design|(course-web-dev))|(course-cybersecurity)|(languages)|(seo)|(breadcrumbs)|(banner)|(blog)|(faq)|(request-syllabus)|(download-syllabus)|(application-form)/"
        }
      }
    ) {
      ...TranslationFragment
    }
    allDatoCmsAssetsByPage(
      filter: { page: { eq: "course" }, locale: { eq: $datoLocale } }
    ) {
      nodes {
        page
        locale
        assets {
          gatsbyImageData(layout: FULL_WIDTH)
          title
          alt
          customData
          basename
        }
      }
    }
  }
`;

export default Course;
