import React from 'react';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
  image?: React.ReactNode;
  subtitle: string;
  title: string;
};

const NewCtaCampusCourse = (props: Props): React.ReactElement => {
  const { children, image, title, subtitle } = props;

  return (
    <Flex
      justify="space-between"
      margin={[null, null, null, 'auto']}
      maxW={[null, null, null, '1296px']}
      px={[3, null, 4, 0]}
      wrap="wrap"
    >
      <Box w={['100%', null, '60%']}>
        <Text textStyle="jumbo">{title}</Text>
        <Text color="darkBlue.64" mt="2" textStyle="body3">
          {subtitle}
        </Text>
        {children}
      </Box>
      {image && (
        <>
          <Spacer />
          <Box
            borderRadius="card"
            height="100%"
            mt={[3, null, 0]}
            overflow="hidden"
            w={['100%', null, '36%']}
          >
            {image}
          </Box>
        </>
      )}
    </Flex>
  );
};

export default NewCtaCampusCourse;
